import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reisedurchfall-vorbeugen"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627 active-trail active">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail active"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf active-trail">
                        <a
                          href="/reisedurchfall-vorbeugen/"
                          title
                          className="active-trail active"
                        >
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/gesundheitsratschlage-fur-die-reise/" title>
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/kritische-reiseziele/" title>
                          Kritische Reiseziele
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reisediarrhoe-behandlung/" title>
                          Behandlung von Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-12" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>
                        REISE
                        <br />
                        APOTHEKE
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_reisen.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Was gehört in die Reise-
                        <br />
                        apotheke? Finden Sie hier die praktische Checkliste.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/gesundheitsratschlage-fur-die-reise/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/reisedurchfall-vorbeugen"
                      typeof="sioc:Item foaf:Document"
                      className="ds-1col node node-simple-page view-mode-1_col clearfix"
                    >
                      <div>
                        <h1>Vorbeugung von Reisedurchfall </h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/reisen.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Reisedurchfall kann verschiedene Ursachen haben.
                            Fettiges und stark gewürztes Essen oder übermässiger
                            Alkoholkonsum können verantwortlich sein. Eine der häufigsten
                            Ursachen sind verunreinigtes Wasser oder Essen
                            und unserem Magen-Darm-System noch unbekannte Arten von Bakterien.{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>
                                Tipps, zur Vermeidung von verunreinigtem Wasser
                              </h3>
                              <ul>
                                <li>
                                  Trinken Sie statt Leitungswasser Wasser aus
                                  Flaschen oder andere Getränke aus Flaschen
                                  oder Dosen
                                </li>
                                <li>
                                  Verwenden Sie auch zum Zähneputzen statt
                                  Leitungswasser abgefülltes Wasser
                                </li>
                                <li>
                                  Vermeiden Sie auch Getränke mit Eiswürfeln,
                                  die aus Leitungswasser hergestellt wurden
                                </li>
                                <li>
                                  Wenn Sie schwimmen gehen, sollten Sie darauf
                                  achten, kein Wasser zu schlucken
                                </li>
                              </ul>
                              <h3>
                                Tipps zur Vermeidung von verunreinigte
                                Nahrungsmittel
                              </h3>
                              <ul>
                                <li>
                                  Waschen Sie Ihre Hände vor dem Essen mit Seife
                                </li>
                                <li>
                                  Speisen sollten durchgekocht sein. Achten Sie
                                  darauf, dass die Gerichte angemessen heiss
                                  sind
                                </li>
                                <li>
                                  Achten Sie möglichst darauf, dass übrig
                                  gebliebenes Essen abgedeckt oder im
                                  Kühlschrank aufbewahrt wird
                                </li>
                                <li>
                                  Vermeiden Sie rohe Nahrungsmittel, die mit
                                  Leitungswasser gewaschen wurden
                                </li>
                                <li>
                                  Essen Sie kein rohes Fleisch und keine rohen
                                  Meeresfrüchte
                                </li>
                                <li>Kaufen Sie kein Essen an Strassenständen</li>
                                <li>Früchte sollten Sie nur geschält essen</li>
                                <li>
                                  Vermeiden Sie Schalentiere und ungekochte oder
                                  -gebratene Meeresfrüchte
                                </li>
                                <li>
                                  Vermeiden Sie Milch, die nicht pasteurisiert
                                  ist (Abkochen ist eine Möglichkeit)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
